import React from 'react'
import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography';
import Layout from '../layout'
import SEO from "../components/seo"

import Container from '@material-ui/core/Container'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types';

const Page = ({ data }) => {
  const activity = data.allContentfulActivity.edges[0].node

  const contentfulOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="body1" paragraph>{children}</Typography>
      ),
    },
  };

  return (
    <Layout>
      <SEO title={activity.activityTitle} />
      
      <Container fixed style={{ minHeight: '100vh', marginBottom: '25px', paddingTop: '1rem' }}>
        <Typography variant="h4" component="h1" paragraph>{activity.title}</Typography>
        {documentToReactComponents(JSON.parse(activity.description.raw), contentfulOptions)}
      </Container>

    </Layout>
  )
}

export const query = graphql`
  query ActivityQuery($id: String) {
    allContentfulActivity(filter: { id: { eq: $id } }) {
      edges {
        node {
          id,
          title,
          description { raw }
        }
      }
    }
  }
`

export default Page